import { get } from 'lodash'
import AppImage from '@theme/components/atom/AppImage'
import AppNumber from '@theme/components/atom/AppNumber'
import CartItemAmount from '@theme/components/cart/CartItemAmount'
import CheckoutItemLineBelowNameHook from '@theme/components/cart/CheckoutItemLineBelowNameHook'
import CheckoutItemLineSecondRowHook from '@theme/components/cart/CheckoutItemLineSecondRowHook'
import DeliveryDate from '@theme/components/shop/DeliveryDate'
import PricesMixin from '~/mixins/PricesMixin'
import ProductLinkMixin from '~/mixins/ProductLinkMixin'
import RemoveFromCart from '@theme/components/cart/RemoveFromCart'

export default {
  mixins: [PricesMixin, ProductLinkMixin],
  components: {
    AppImage,
    AppNumber,
    CartItemAmount,
    CheckoutItemLineBelowNameHook,
    CheckoutItemLineSecondRowHook,
    DeliveryDate,
    RemoveFromCart,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    showAvailability: {
      type: Boolean,
      default() {
        return this.$themeSettings.global.showAvailability
      },
    },
  },
  computed: {
    productPath() {
      return this.getProductPath(this.item.product)
    },
    itemFormattedPrices() {
      return this.getFormattedPrices({ prices: this.item.prices })
    },
    pricePrimaryLabel() {
      const { label, withTax } = this.getPriceSettings('primary')
      return label && this.$t('CheckoutItemLine.' + (withTax ? 'priceWithTax' : 'priceWithoutTax'))
    },
    priceSecondaryLabel() {
      const { label, withTax } = this.getPriceSettings('secondary')
      return label && this.$t('CheckoutItemLine.' + (withTax ? 'priceWithTax' : 'priceWithoutTax'))
    },
    quantityMin() {
      const amountMin = Number(get(this.item, 'variant.availability.amountMin'))
      return amountMin && this.quantityMultiplier
        ? Number((amountMin / this.quantityMultiplier).toFixed(this.decimalPosition))
        : amountMin || 1
    },
    quantityMax() {
      const amountMax = Number(get(this.item, 'variant.availability.amountMax'))
      return amountMax && this.quantityMultiplier
        ? Number((amountMax / this.quantityMultiplier).toFixed(this.decimalPosition))
        : amountMax || Infinity
    },
    quantityMultiplier() {
      return Number(get(this.metersParameter, 'values') || 1)
    },
    quantityStep() {
      const amountStep = Number(get(this.item, 'variant.availability.amountStep'))
      return amountStep && this.quantityMultiplier
        ? Number((amountStep / this.quantityMultiplier).toFixed(this.decimalPosition))
        : amountStep || 1
    },
    metersParameter() {
      const parameters = get(this.item, 'variant.content.parameters') || []
      return parameters.find(parameter => parameter.entityId === this.$contentSettings.metersParameterId)
    },
    isMetersCalculation() {
      return this.$themeSettings.components.PageProductDetail.showMetersCalculator && this.metersParameter
    },
  },
}
