import LoadingMixin from '~/mixins/LoadingMixin'

export default {
  mixins: [LoadingMixin],
  props: {
    ariaLabel: String,
    ariaLabelledBy: String,
    iconOnRight: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: [String, Boolean],
      default() {
        return require('@icon/bin.svg?raw')
      },
    },
  },
}
