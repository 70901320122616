import { notifications } from '~/app/notifications'
import RemoveButton from '@theme/components/atom/RemoveButton'

export default {
  components: {
    RemoveButton,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async remove() {
      this.$refs.removeFromCart.startLoading()
      const transNotificationSuccess = this.$t('RemoveFromCart.notifications.success')
      const response = await this.$store.dispatch('cart/REMOVE_FROM_CART', this.item)
      if (response) {
        notifications.success(transNotificationSuccess)
      }
      if (this.$refs.removeFromCart) this.$refs.removeFromCart.stopLoading()
    },
  },
}
