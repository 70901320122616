import InfoCountMixin from '~/mixins/InfoCountMixin'
import InfoCounter from '@theme/components/atom/InfoCounter'

export default {
  components: {
    InfoCounter,
  },
  mixins: [InfoCountMixin],
  computed: {
    link() {
      return this.localePath({
        name: this.$contentSettings.functions.favourites ? 'user-favourites' : 'user-shoppingLists-id',
        params: { id: this.$store.state.user.wishList && this.$store.state.user.wishList.id },
      })
    },
  },
}
